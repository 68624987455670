<template>
  <v-app-bar color="white"
             flat class="send">
    <v-text-field

        v-model="message"
        :append-outer-icon="message ? 'mdi-send' : 'mdi-microphone'"
        rounded
        filled
        dense
        clear-icon="mdi-close-circle"
        clearable
        label="Message"
        type="text"
        @click:append-outer="sendMessage"
        @click:clear="clearMessage"
    ></v-text-field>
    <v-dialog
        v-model="dialog"
        width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            elevation="2"
            fab
            v-bind="attrs"
            v-on="on"
        >
          <v-icon>
            fa-plus
          </v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Envoyer un fichier
        </v-card-title>

        <v-card-text>
          <v-file-input
              v-model="file"
              chips
              multiple
              show-size
              label="ajouter un fichier"
          ></v-file-input>
          <v-progress-circular
              v-if="sendFile"
              :rotate="-90"
              :size="100"
              :width="15"
              :value="value"
              color="primary"
          >
            {{ value }}
          </v-progress-circular>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red"
              text
              @click="dialog = false"
          >
            Annuler
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="sendFile"
          >
            Envoyer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import firebase from "firebase/compat";
import {mapGetters} from "vuex";


export default {
  name: "MessageForm",
  data: () => ({
    messagesRef: firebase.database().ref("messages"),
    privateMessagesRef: firebase.database().ref("privateMessages"),
    errors: [],
    firstLoad: true,
    dialog: false,
    selected: [2],
    panel: [2],
    password: "Password",
    show: false,
    message: "",
    marker: true,
    iconIndex: 0,
    file: null,
    storageRef: firebase.storage().ref(),
    uploadTask: null,
    uploadState: null,
    mime: require("mime-types"),
    percent: 0,
    value: 0,
  }),
  computed : {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    ...mapGetters(["currentChannel", "currentUser", "isPrivate"]),
    hasErrors () {
      return this.errors.length > 0
    }
  },
  methods: {
    sendMessage () {
      this.resetIcon()
      this.getMessageRef().child(this.currentChannel.id).push().set(this.createMessage()).then( () => {

      }).catch(error => {
        console.log(error)
      })

      this.clearMessage()
    },
    createMessage(fileUrl = null) {
      let message = {
        timestamp: firebase.database.ServerValue.TIMESTAMP,
        user: {
          name: this.currentUser.displayName,
          avatar: this.currentUser.photoURL,
          id: this.currentUser.uid
        }
      }
      if(fileUrl == null){
        message["content"] = this.message;
      } else {
        message["file"] = fileUrl;
      }
      return message
    },
    getMessageRef () {
      if(this.isPrivate){
        return this.privateMessagesRef
      }else{
        return this.messagesRef
      }
    },
    sendFile(){
      if(this.file[0] !== null){
        let metadata = { contentType: this.mime.lookup(this.file[0].name)}
        this.uploadFile(this.file[0], metadata)
        this.dialog = false
      }
    },
    uploadFile(file, metadata) {
      let pathToUpload = this.currentChannel.id
      let ref = this.getMessageRef()
      let filePath = this.getPath() + '/' + this.file[0].name
      //Upload du Fichier
      this.uploadTask = this.storageRef.child(filePath).put(file, metadata)
      this.uploadState = "uploading"
      this.uploadTask.on(
        "state_changed",
        (snap) => {
        // Upload en cours
        this.percent = (snap.bytesTransferred / snap.totalBytes) * 100
        console.log(this.percent)
      }, error => {
        // Erreur
        this.errors.push(error.message)
        this.uploadState = 'error'
        this.uploadTask = null
      }, () => {
        // Upload finit
        this.uploadState = 'done'
        //Récupération de l'url du Fichier
        this.uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.sendFileMessage(downloadURL, ref, pathToUpload)
        });
      })
    },
    sendFileMessage(fileUrl, ref, pathToUpload){

      ref.child(pathToUpload).push().set(this.createMessage(fileUrl)).then( () => {
        this.$nextTick(() => {

        })
      }).catch( error => {
        this.errors.push(error.message)
      })

    },
    getPath() {
      if(this.isPrivate){
        return "messenger/private/" + this.currentChannel.id;
      }else{
        return "messenger/public";
      }
    },
    clearMessage () {
      this.message = ''
    },
    resetIcon () {
      this.iconIndex = 0
    },
  },
  beforeDestroy () {
    clearInterval(this.interval)
    if(this.uploadTask !== null){
      this.uploadTask.cancel()
      this.uploadTask = null
    }
  },
  mounted () {
    this.interval = setInterval(() => {
      this.value = this.percent
    }, 1000)
  },
}
</script>

<style scoped>
.send{
  position: sticky;
  bottom: 0%;
  /*margin: 100px 350px 0px 650px;*/
  z-index: 1;
  width: 100%;
}
</style>