<template>
  <v-col cols="12" sm="3" class="border" >
    <div class="stick-g">

    <v-app-bar flat color="rgba(0,0,0,0)">
      <v-toolbar-title class="title"> Discussions </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>fa-ellipsis-h</v-icon>
      </v-btn>
    </v-app-bar>
    <v-app-bar flat color="rgba(0,0,0,0)">
      <v-text-field
          filled
        rounded
        label="Rechercher un contact"
        append-icon="mdi-magnify"
        color="grey"
      ></v-text-field>
    </v-app-bar>
    <v-item-group mandatory >
      <v-container>
        <v-row>
              <v-col
                  v-for="channel in channels"
                  :key="channel.id"
                  :class="{ 'is_active': setChannelActive(channel) }"
                  @click="changeChannel(channel)"
                  cols="12"
                  md="3"
              >
                <v-item v-slot="{ active, toggle }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      :color="active ? '#EAEAEA' : 'white'"
                      class="d-flex align-center mx-2"
                      fab
                      dark
                      @click="toggle"
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-scroll-y-transition>
                      <v-img
                          class="text-h2 flex-grow-1 text-center"
                          :src="channel.icon"
                          contain
                          max-height="50"
                          max-width="50"
                      />
                    </v-scroll-y-transition>
                  </v-btn>
                    </template>
                    <span>{{ channel.name }}</span>
                  </v-tooltip>
                </v-item>
              </v-col>
        </v-row>
      </v-container>
    </v-item-group>
    <Users />
    </div>
  </v-col>
</template>
<script>
import { mapGetters } from "vuex";
import firebase from "firebase/compat";
import Users from "./Users";

export default {
  components: { Users },
  data: () => ({
    channels: [],
    new_channel: "",
    channelsRef: firebase.database().ref("channels"),
    messagesRef: firebase.database().ref("messages"),
    errors: [],
    firstLoad: true,
    notifCount: [],
    channel: null,
    dialog: false,
    selected: [2],
    files: [
      { text: "Landing_page.zip", icon: " mdi-cloud-upload" },
      { text: "Requirements.pdf", icon: " mdi-cloud-upload" },
      { text: "Uwagi.docx", icon: " mdi-cloud-upload" },
    ],
    panel: [2],

    password: "Password",
    show: false,
    message: "Type a message here",
    marker: true,
    iconIndex: 0,
  }),
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    ...mapGetters(["currentChannel", "isPrivate"]),
    hasErrors() {
      return this.errors.length > 0;
    },
  },
  watch : {
    isPrivate(){
      if(this.isPrivate){
        this.resetNotifications()
      }
    }
  },
  mounted() {
    this.addListeners();
  },
  methods: {
    sendMessage() {
      this.resetIcon();
      this.clearMessage();
    },
    clearMessage() {
      this.message = "";
    },
    resetIcon() {
      this.iconIndex = 0;
    },
    addListeners() {
      this.channelsRef.on("child_added", (snap) => {
        this.channels.push(snap.val());

        if (this.firstLoad && this.channels.length > 0) {
          this.$store.dispatch("setCurrentChannel", this.channels[0]);
          this.channel = this.channels[0]
        }
        this.firsLoad = false;

        //Rajout du listener pour gerér les notifications
        this.addCountListener(snap.key)
      });
    },
    addCountListener(channelId){
      this.messagesRef.child(channelId).on('value', snap => {
        this.handleNotifications(channelId, this.currentChannel.id, this.notifCount, snap)
      })
    },
    handleNotifications(channelId, currentChannelId, notifCount, snap){
      let lastTotal = 0

      let index = notifCount.findIndex(el =>el.id === channelId)
      if(index !== -1){
        if (channelId !== currentChannelId){
          lastTotal = notifCount[index].total

          if (snap.numChildren() - lastTotal > 0 ){
            notifCount[index].notif = snap.numChildren() - lastTotal
          }
        }

        notifCount[index].lastKnownTotal = snap.numChildren()

      } else {
        notifCount.push({
          id: channelId,
          total: snap.numChildren(),
          lastKnownTotal: snap.numChildren(),
          notif: 0
        })
      }
    },
    getNotification(channel){
      let notif = 0
      this.notifCount.forEach(el => {
        if(el.id === channel.id){
          notif = el.notif
        }
      })
      return notif
    },
    addChannel() {
      let key = this.channelsRef.push().key;

      let newChannel = { id: key, name: this.new_channel };
      this.channelsRef
        .child(key)
        .update(newChannel)
        .then(() => {
          (this.new_channel = ""), (this.dialog = false);
        })
        .catch((error) => {
          this.errors.push(error.message);
        });
    },
    changeChannel(channel) {
      this.$store.dispatch("setPrivate", false);
      this.$store.dispatch("setCurrentChannel", channel);
      this.channel = channel;
    },
    resetNotifications(){
      let index = this.notifCount.findIndex( el => el.id === this.channel.id)
      if(index !== -1){
        this.notifCount[index].total = this.notifCount[index].lastKnownTotal
        this.notifCount[index].notif = 0
      }
    },
    detachListeners() {
      this.channelsRef.off();
      this.channels.forEach((el) => {
        this.messagesRef.child(el.id).off();
      });
    },
    setChannelActive(channel) {
      return channel.id === this.currentChannel.id;
    },
  },
  beforeDestroy() {
    this.detachListeners();
  },
};
</script>
<style scoped>
.border {
  border-right: 1px solid grey;
}
.stick-g{
  position: sticky;
  top:0%;
}
</style>
