<template>
    <v-container fluid class="chat" >
      <v-row elevation="4" class="stick-g">
        <Channels/><Messages /><Destinataire/>

      </v-row>
    </v-container>
</template>
<script>
import Destinataire from "../components/Destinataire";
import Channels from "../components/Channels";
import Messages from "../components/Messages";
export default {
  name: "Messagerie",
  components: {Channels,Messages, Destinataire},
  
};
</script>
<style scoped>
.border {
  border-right: 1px solid grey;
}
.chat{
  /*box-shadow: 3px 3px 5px black;*/
  /*border-radius: 30px;*/
}
.stick-g{
  position: sticky;
  top: 5%;
}
.height-g{
  max-height: 550px;
}
</style>