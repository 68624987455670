<template>
  <v-col cols="12" sm="6" class="border">
    <v-app-bar color="white" flat class="stick-g">
      <v-badge
          bordered
          bottom
          color="green"
          dot
          offset-x="11"
          offset-y="13"
      >
        <v-avatar class="mt-n7" size="40" elevation="10">
          <img src="https://cdn.vuetifyjs.com/images/lists/1.jpg" />
        </v-avatar>
      </v-badge>
      <v-toolbar-title class="title pl-0 ml-2 mt-n4">
        {{ channelName }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title class="title pl-0 ml-6 mt-n4">
        {{currentUser.displayName}}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-divider vertical inset class="mt-n1"></v-divider>
      <v-btn
          color="teal accent-4"
          icon
          class="mt-n5"
      >
        <v-icon>mdi-cog</v-icon>
      </v-btn>

    </v-app-bar>

    <SingleMessage :message="message" v-for="message in messages" :key="message.id" />
    <MessageForm />

  </v-col>
</template>
<script>
import {mapGetters} from "vuex";
import firebase from "firebase/compat";
import MessageForm from "./MessageForm";
import SingleMessage from "./SingleMessage";

export default {
  components: {SingleMessage, MessageForm },
  data: () => ({
    channels: [],
    new_channel: '',
    channelsRef: firebase.database().ref("channels"),
    messagesRef: firebase.database().ref("messages"),
    privateMessagesRef: firebase.database().ref("privateMessages"),
    messages: [],
    listeners: [],
    errors: [],
    firstLoad: true,
    notifCount: [],
    channel: null,
    dialog: false,
    selected: [2],
    items: [
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
        subtitle: `I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
        title: 'Ali Connors',
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
        subtitle: `Wish I could come, but I'm out of town this weekend.`,
        title: 'me, Scrott, Jennifer',
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
        subtitle: 'Do you have Paris recommendations? Have you ever been?',
        title: 'Sandra Adams',
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
        subtitle: 'Have any ideas about what we should get Heidi for her birthday?',
        title: 'Trevor Hansen',
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
        subtitle: 'We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
        title: 'Britta Holt',
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
        subtitle: `I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
        title: 'Connors',
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
        subtitle: `Wish I could come, but I'm out of town this weekend.`,
        title: ' Jennifer',
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
        subtitle: 'Do you have Paris recommendations? Have you ever been?',
        title: 'Sandra ',
      },
    ],
    files: [
      { text: 'Landing_page.zip', icon: ' mdi-cloud-upload' },
      { text: 'Requirements.pdf', icon: ' mdi-cloud-upload' },
      { text: 'Uwagi.docx', icon: ' mdi-cloud-upload' },
    ],
    panel: [2],

    password: "Password",
    show: false,
    marker: true,
    iconIndex: 0,

  }),
  computed : {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    ...mapGetters(["currentChannel", "currentUser", "isPrivate"]),
    // eslint-disable-next-line vue/return-in-computed-property
    channelName(){
      if(this.channel !== null){
        return this.isPrivate ? '@ ' + this.channel.name : '# ' + this.channel.name
      }
    },
    hasErrors () {
      return this.errors.length > 0
    }
  },
  mounted () {
    this.addListeners()
  },
  watch : {
    currentChannel () {
      this.detachListeners()
      this.addListeners()
      this.channel = this.currentChannel
    }
  },
  methods: {
    resetIcon () {
      this.iconIndex = 0
    },
    addListeners () {
      let ref = this.getMessageRef()
      ref.child(this.currentChannel.id).on("child_added", (snap) => {
        let message = snap.val()
        message["id"] = snap.key;
        this.messages.push(message)
      })
      this.addToListeners(this.currentChannel.id, ref, "child_added");
    },
    addToListeners(id, ref, event){
      let index = this.listeners.findIndex( el => {
        return el.id === id && el.ref === ref && el.event === event
      })
      if(index === -1){
        this.listeners.push({id: id, ref: ref, event: event})
      }
    },
    detachListeners () {
      this.listeners.forEach( listener => {
        listener.ref.child(listener.id).off(listener.event)
      })
      this.listeners = []
      this.messages = []
    },
    getMessageRef () {
      if(this.isPrivate){
        return this.privateMessagesRef
      }else{
        return this.messagesRef
      }
    }
  },
  beforeDestroy () {
    this.detachListeners()
  }
};
</script>
<style scoped>
.border {
  border-right: 1px solid grey;
}
.stick-g{
  position: sticky;
  top: 0%;
  z-index: 1;
}
.messages__container{
  margin-right: 300px;
}
</style>