<template>
  <v-app-bar color="rgba(0,0,0,0)" flat class="mb-16" v-if="selfMessage(message.user)"
  >
    <v-spacer></v-spacer>
    <v-card class="mt-10 mr-2 comment__self" max-width="350px" rounded dark>
      <v-list-item three-line transition="slide-x-reverse-transition">
        <v-list-item-content >
          <div class="mb-4" v-if="!isFile(message)">
            {{ message.content }}
          </div>
          <a :href="message.file" target="_blank" v-else><img class="mb-4" height="50px" width="64px" :src="message.file" :alt="message.name" ></a>

          <v-list-item-subtitle><span>{{ message.timestamp | fromNow}}</span></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card >
    <v-badge
        bordered
        bottom
        color="green"
        dot
        offset-x="10"
        offset-y="10"
    >
      <v-avatar class="mt-n5 " size="30" elevation="10">
        <img :src="message.user.avatar" />
      </v-avatar>
    </v-badge>
  </v-app-bar>
  <v-app-bar color="rgba(0,0,0,0)" flat class="mb-16" v-else
  >
    <v-badge
        bordered
        bottom
        color="green"
        dot
        offset-x="10"
        offset-y="10"
    >
      <v-avatar class="mt-n5 " size="30" elevation="10">
        <img :src="message.user.avatar" />
      </v-avatar>
    </v-badge>
    <v-card class="mt-10 mr-2" max-width="350px" outlined rounded dark>
      <v-list-item three-line transition="slide-x-reverse-transition">
        <v-list-item-content rounded >
          <div class="mb-4" v-if="!isFile(message)">
            {{ message.content }}
          </div>
          <a :href="message.file" target="_blank" v-else><img class="mb-4" height="50px" width="64px" :src="message.file" :alt="message.name" ></a>

          <v-list-item-subtitle><span>{{ message.timestamp | fromNow}}</span></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card >
    <v-spacer></v-spacer>
  </v-app-bar>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment"

export default {
  name: "SingleMessage",
  props: ["message"],
  mounted() {
    console.log(this.message)
  },
  computed : {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    selfMessage(user){
      return user.id === this.currentUser.uid
    },
    isFile(message){
      return message.content == null && message.file != null
    }
  },
  filters: {
    fromNow (value){
      return moment(value).fromNow()
    }
  }
  
}
</script>

<style scoped>
.comment__self{
  background-color: #14ad95;
  margin-right: 0px;
}
</style>