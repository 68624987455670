<template>
        <v-col cols="12" sm="3" class=".d-none .d-md-flex .d-lg-none">
          <div class="stick-g">
          <v-card class="text-center mt-8 mb-3 " shaped >
            <v-badge
                bordered
                bottom
                rounded
                color="green"
                dot
                offset-x="11"
                offset-y="13"
            >
              <v-avatar class="mt-n7" size="60" elevation="10">
                <img src="https://cdn.vuetifyjs.com/images/lists/1.jpg" />
              </v-avatar>
            </v-badge>
            <v-card-title class="layout justify-center">Fernando Gaucho</v-card-title>
            <v-card-subtitle class="layout justify-center">Description / fonction </v-card-subtitle>
            <v-list>
            </v-list>
          </v-card>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <h3>Images(14)</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>

              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel >
              <v-expansion-panel-header>
                <h3>Fichiers(3) </h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list shaped>
                  <v-list-item-group

                  >
                    <v-list-item
                        v-for="(item, i) in files"
                        :key="i"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="item.icon" color="green"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.text"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>


          </v-expansion-panels>
          </div>
        </v-col>
</template>
<script>
import {mapGetters} from "vuex";
import firebase from "firebase/compat";

export default {

  data: () => ({
    channels: [],
    new_channel: '',
    channelsRef: firebase.database().ref("channels"),
    messagesRef: firebase.database().ref('messages'),
    errors: [],
    firstLoad: true,
    notifCount: [],
    channel: null,
    dialog: false,
    selected: [2],
    items: [
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
        subtitle: `I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
        title: 'Ali Connors',
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
        subtitle: `Wish I could come, but I'm out of town this weekend.`,
        title: 'me, Scrott, Jennifer',
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
        subtitle: 'Do you have Paris recommendations? Have you ever been?',
        title: 'Sandra Adams',
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
        subtitle: 'Have any ideas about what we should get Heidi for her birthday?',
        title: 'Trevor Hansen',
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
        subtitle: 'We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
        title: 'Britta Holt',
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
        subtitle: `I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
        title: 'Connors',
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
        subtitle: `Wish I could come, but I'm out of town this weekend.`,
        title: ' Jennifer',
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
        subtitle: 'Do you have Paris recommendations? Have you ever been?',
        title: 'Sandra ',
      },
    ],
    files: [
      { text: 'Landing_page.zip', icon: ' mdi-cloud-upload' },
      { text: 'Requirements.pdf', icon: ' mdi-cloud-upload' },
      { text: 'Uwagi.docx', icon: ' mdi-cloud-upload' },
    ],
    panel: [2],

    password: 'Password',
    show: false,
    message: 'Type a message here',
    marker: true,
    iconIndex: 0,

  }),
  computed : {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    ...mapGetters(['currentChannel']),
    hasErrors () {
      return this.errors.length > 0
    }
  },
  mounted () {
    this.addListeners()
  },
  methods: {
    sendMessage () {
      this.resetIcon()
      this.clearMessage()
    },
    clearMessage () {
      this.message = ''
    },
    resetIcon () {
      this.iconIndex = 0
    },
    addListeners () {
      this.channelsRef.on('child_added', snap => {
        this.channels.push(snap.val())

        if(this.firstLoad && this.channels.length > 0){
          this.$store.dispatch("setCurrentChannel", this.channels[0])
        }
        this.firsLoad = false
      })
    },
    addChannel () {
      let key = this.channelsRef.push().key

      let newChannel = { id: key, name: this.new_channel }
      this.channelsRef.child(key).update(newChannel).then( () => {
        this.new_channel = '',
        this.dialog = false
      }).catch(error => {
        this.errors.push(error.message)
      })
    },
    changeChannel(channel){
      this.$store.dispatch('setCurrentChannel', channel)
      this.channel = channel
    },
    detachListeners () {
      this.channelsRef.off()
      this.channels.forEach( el => {
        this.messagesRef.child(el.id).off()
      })
    },
    setChannelActive(channel){
      return channel.id === this.currentChannel.id
    }
  },
  beforeDestroy () {
    this.detachListeners()
  }
};
</script>
<style scoped>
.border {
  border-right: 1px solid grey;
}
.stick-g{
  position: sticky;
  top:5%;
}
</style>