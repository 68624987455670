<template>
  <v-list two-line color="rgba(0,0,0,0)">
    <v-list-item-group
        active-class="blue lighten-4"
        multiple
    >
      <template v-for="(user, index) in users" >
        <v-list-item :key="user.name">
          <v-badge
              v-if="isOnline(user)"
              bordered
              bottom
              color="green"
              dot
              offset-x="22"
              offset-y="26"
          >
            <v-list-item-avatar>
              <v-img v-if="error" :src="user.avatar"></v-img>
            </v-list-item-avatar>
          </v-badge>
          <v-badge
              v-else-if="!isOnline(user)"
              bordered
              bottom
              color="red"
              dot
              offset-x="22"
              offset-y="26"
          >
            <v-list-item-avatar>
              <v-img :src="user.avatar"></v-img>
            </v-list-item-avatar>
          </v-badge>
          <template >
            <v-list-item-content :class="{'is_active' : isActive(user)}" @click.prevent="changeChannel(user)">
              <v-list-item-title v-text="user.name"></v-list-item-title>

              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-list-item>

        <v-divider
            v-if="index < users.length - 1"
            :key="index"
        ></v-divider>
      </template>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex'
import firebase from "firebase/compat";
export default {
  name: 'Users',
  data() {
    return {
      users: [],
      usersRef: firebase.database().ref('users'),
      connectedRef: firebase.database().ref('.info/connected'),
      presenceRef: firebase.database().ref('presence'),
      privateMessagesRef: firebase.database().ref('privateMessages'),
      notifCount: [],
      channel: null
    }
  },
  computed: {
    ...mapGetters(["currentUser", "currentChannel", "isPrivate"]),
  },
  mounted() {
    this.addListeners()
  },
  methods: {
    addListeners() {
      this.usersRef.on('child_added', snap => {
        if (this.currentUser.uid !== snap.key) {
          let user = snap.val()
          user['uid'] = snap.key
          user['status'] = 'offline'
          this.users.push(user)
        }
      })
      //Check du status utilisateur
      this.presenceRef.on('child_added', snap => {
        if(this.currentUser.uid !== snap.key){
          this.addStatusToUser(snap.key)
        }
      })
      // bllbbl
      this.presenceRef.on('child_removed', snap => {
        if(this.currentUser.uid !== snap.key){
          this.addStatusToUser(snap.key, false)
        }
      })
      //bllffk
      this.connectedRef.on('value', snap => {
        if(snap.val() === true){
          let ref  = this.presenceRef.child(this.currentUser.uid)
          ref.set(true)
          ref.onDisconnect().remove( err => {
            if(err !== null) console.log(err)
          })
        }
      })
    },
    addStatusToUser(userId, connected = true){
      let index = this.users.findIndex( user => user.uid === userId)
      if(index !== -1){
        connected === true ? this.users[index].status = 'online' : this.users[index].status = 'offline'
      }
    },
    isOnline(user){
      return user.status === "online";
    },
    changeChannel(user){
      let channelId = this.getChannelId(user.uid)
      let channel = { id: channelId, name: user.name}
      this.channel = channel
      this.$store.dispatch("setPrivate", true);
      this.$store.dispatch("setCurrentChannel", channel);
      console.log('change Channel')
    },
    isActive(user){
      let channelId = this.getChannelId(user.uid)
      return this.currentChannel.id === channelId
    },
    getChannelId(userId){
      return userId < this.currentUser.uid ? userId+'/'+this.currentUser.uid : this.currentUser.uid + '/'+userId
    },
    detachListeners() {
      this.usersRef.off()
      this.presenceRef.off()
      this.connectedRef.off()
    }
  },
  beforeDestroy() {
    this.detachListeners()
  }
}
</script>

<style scoped>

</style>